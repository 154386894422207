.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.cases-wrapper {
  background-color: #fff;
}
.cases-wrapper .data-banner-wrapper {
  box-sizing: border-box;
  height: 34.375vw;
  padding-top: 4.895833333333333vw;
  padding-bottom: 6.406249999999999vw;
  background-color: #00092a;
  background-image: url(https://mktv-in-cdn.mockuai.com/16252259933903902.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.cases-wrapper .data-banner-wrapper h2.title {
  margin: 0;
  margin-bottom: 1.4583333333333333vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.cases-wrapper .data-banner-wrapper > .desc {
  width: 65.9375vw;
  margin: 0 auto 1.45833333vw;
  text-align: center;
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #ffffff;
  opacity: 0.6;
}
.cases-wrapper .data-banner-wrapper .data-box {
  display: flex;
  align-items: center;
  margin-left: 24.21875vw;
}
.cases-wrapper .data-banner-wrapper .data-box .data-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cases-wrapper .data-banner-wrapper .data-box .data-item .num-line {
  display: flex;
  align-items: flex-end;
}
.cases-wrapper .data-banner-wrapper .data-box .data-item .num-line .num {
  line-height: 3.5416666666666665vw;
  font-size: 3.125vw;
  font-family: DINCond-Light, DINCond;
  font-weight: 300;
  color: #ffffff;
}
.cases-wrapper .data-banner-wrapper .data-box .data-item .num-line .unit,
.cases-wrapper .data-banner-wrapper .data-box .data-item .num-line .plus {
  line-height: 1.4;
  font-size: 1.0416666666666665vw;
  font-family: PingFangSC-Thin, PingFang SC;
  font-weight: 100;
  color: #fff;
}
.cases-wrapper .data-banner-wrapper .data-box .data-item .num-line .unit {
  margin-bottom: 0.5208333333333333vw;
}
.cases-wrapper .data-banner-wrapper .data-box .data-item .num-line .plus {
  align-self: center;
}
.cases-wrapper .data-banner-wrapper .data-box .data-item .text-line {
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #ffffff;
}
.cases-wrapper .data-banner-wrapper .data-box .data-item:nth-child(1) {
  width: 5vw;
  margin-right: 9.53125vw;
}
.cases-wrapper .data-banner-wrapper .data-box .data-item:nth-child(2) {
  width: 7.8125vw;
  margin-right: 8.020833333333334vw;
}
.cases-wrapper .data-banner-wrapper .data-box .data-item:nth-child(3) {
  width: 8.125vw;
  margin-right: 6.458333333333334vw;
}
.cases-wrapper .data-banner-wrapper .data-box .data-item:nth-child(4) {
  width: 10.833333333333334vw;
}
.cases-wrapper .data-banner-wrapper .anchor-title {
  text-align: center;
  margin: 4.58333333vw auto 0.41666667vw;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff6600;
}
.cases-wrapper .data-banner-wrapper .anchor-desc {
  text-align: center;
  line-height: 1.4;
  font-size: 1.0416666666666665vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.cases-wrapper .anchor-cases-wrapper {
  box-sizing: border-box;
  position: relative;
  top: -3.125vw;
  width: 75vw;
  height: 30.729166666666668vw;
  padding: 3.125vw;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.09);
  border-radius: 0.8333333333333334vw;
}
.cases-wrapper .anchor-cases-wrapper .swiper-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2.2916666666666665vw;
  height: 2.2916666666666665vw;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}
.cases-wrapper .anchor-cases-wrapper .swiper-arrow.swiper-button-disabled {
  display: none;
}
.cases-wrapper .anchor-cases-wrapper .swiper-arrow.prev {
  left: 0.6770833333333334vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16252291642899934.png);
}
.cases-wrapper .anchor-cases-wrapper .swiper-arrow.next {
  right: 0.9375vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16252259933873886.png);
}
.cases-wrapper .anchor-cases-wrapper .swiper-pagination {
  bottom: 3.125vw;
  left: 50%;
  transform: translateX(-50%);
}
.cases-wrapper .anchor-cases-wrapper .swiper-pagination /deep/ .swiper-pagination-bullet {
  width: 1.25vw;
  height: 0.3125vw;
  margin-right: 0.4166666666666667vw;
  border-radius: 0.20833333333333334vw;
  background-color: #ddd;
}
.cases-wrapper .anchor-cases-wrapper .swiper-pagination /deep/ .swiper-pagination-bullet:last-child {
  margin-right: 0;
}
.cases-wrapper .anchor-cases-wrapper .swiper-pagination /deep/ .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #ff6600;
}
.cases-wrapper .anchor-cases-wrapper .swiper-container {
  width: 62.5vw;
}
.cases-wrapper .anchor-cases-wrapper .case-item {
  display: flex;
  align-items: center;
  background-color: #fff;
}
.cases-wrapper .anchor-cases-wrapper .case-item .image {
  position: relative;
  flex: 0 0 37.5vw;
  width: 37.5vw;
  height: 21.041666666666668vw;
  margin-right: 2.083333333333333vw;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}
.cases-wrapper .anchor-cases-wrapper .case-item .image .main {
  width: 100%;
}
.cases-wrapper .anchor-cases-wrapper .case-item .image .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3.125vw;
  height: 3.125vw;
}
.cases-wrapper .anchor-cases-wrapper .case-item .detail .title {
  margin-bottom: 2.1875vw;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.cases-wrapper .anchor-cases-wrapper .case-item .detail .data-box {
  display: flex;
  align-items: center;
}
.cases-wrapper .anchor-cases-wrapper .case-item .detail .data-box .data-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 4.166666666666666vw;
}
.cases-wrapper .anchor-cases-wrapper .case-item .detail .data-box .data-item:last-child {
  margin-right: 0;
}
.cases-wrapper .anchor-cases-wrapper .case-item .detail .data-box .data-item .num-line {
  display: flex;
  align-items: flex-end;
}
.cases-wrapper .anchor-cases-wrapper .case-item .detail .data-box .data-item .num-line .num {
  line-height: 3.5416666666666665vw;
  font-size: 3.125vw;
  font-family: DINCond-Light, DINCond;
  font-weight: 300;
  color: #333333;
}
.cases-wrapper .anchor-cases-wrapper .case-item .detail .data-box .data-item .num-line .unit,
.cases-wrapper .anchor-cases-wrapper .case-item .detail .data-box .data-item .num-line .plus {
  line-height: 1.4;
  font-size: 1.0416666666666665vw;
  font-family: PingFangSC-Thin, PingFang SC;
  font-weight: 300;
  color: #333;
}
.cases-wrapper .anchor-cases-wrapper .case-item .detail .data-box .data-item .num-line .unit {
  margin-bottom: 0.5208333333333333vw;
}
.cases-wrapper .anchor-cases-wrapper .case-item .detail .data-box .data-item .num-line .plus {
  align-self: center;
}
.cases-wrapper .anchor-cases-wrapper .case-item .detail .data-box .data-item .text-line {
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #666666;
}
.cases-wrapper .anchor-cases-wrapper .case-item .detail .desc {
  width: 22.916666666666664vw;
  margin-top: 2.083333333333333vw;
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #666666;
}
.cases-wrapper .backsource-wrapper {
  box-sizing: border-box;
  position: relative;
  height: 41.5625vw;
  padding-top: 1.3541666666666667vw;
}
.cases-wrapper .backsource-wrapper .act {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 22.03125vw;
}
.cases-wrapper .backsource-wrapper > h2.title {
  margin: 0;
  margin-bottom: 0.4166666666666667vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff6600;
}
.cases-wrapper .backsource-wrapper > .desc {
  text-align: center;
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.cases-wrapper .backsource-wrapper .main {
  margin-left: 53.125vw;
}
.cases-wrapper .backsource-wrapper .main .info {
  padding: 10.26041667vw 0;
}
.cases-wrapper .backsource-wrapper .main .info .data-box {
  display: flex;
  align-items: center;
}
.cases-wrapper .backsource-wrapper .main .info .data-box .data-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2.96875vw;
}
.cases-wrapper .backsource-wrapper .main .info .data-box .data-item:nth-child(1) {
  width: 5.625vw;
}
.cases-wrapper .backsource-wrapper .main .info .data-box .data-item:nth-child(2) {
  width: 5.833333333333333vw;
}
.cases-wrapper .backsource-wrapper .main .info .data-box .data-item:nth-child(3) {
  width: 4.947916666666666vw;
}
.cases-wrapper .backsource-wrapper .main .info .data-box .data-item:last-child {
  margin-right: 0;
}
.cases-wrapper .backsource-wrapper .main .info .data-box .data-item .num-line {
  display: flex;
  align-items: flex-end;
}
.cases-wrapper .backsource-wrapper .main .info .data-box .data-item .num-line .num {
  line-height: 3.5416666666666665vw;
  font-size: 3.125vw;
  font-family: DINCond-Light, DINCond;
  font-weight: 300;
  color: #333333;
}
.cases-wrapper .backsource-wrapper .main .info .data-box .data-item .num-line .unit,
.cases-wrapper .backsource-wrapper .main .info .data-box .data-item .num-line .plus {
  line-height: 1.4;
  font-size: 1.0416666666666665vw;
  font-family: PingFangSC-Thin, PingFang SC;
  font-weight: 100;
  color: #333333;
}
.cases-wrapper .backsource-wrapper .main .info .data-box .data-item .num-line .unit {
  margin-bottom: 0.5208333333333333vw;
}
.cases-wrapper .backsource-wrapper .main .info .data-box .data-item .num-line .plus {
  align-self: center;
}
.cases-wrapper .backsource-wrapper .main .info .data-box .data-item .text-line {
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #666666;
}
.cases-wrapper .backsource-wrapper .main .info .desc {
  width: 30.208333333333332vw;
  margin-top: 2.1875vw;
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #666666;
}
.cases-wrapper .backsource-wrapper .mobile {
  position: absolute;
  left: 21.302083333333332vw;
  bottom: 1.5104166666666667vw;
}
.cases-wrapper .backsource-wrapper .mobile .decorate {
  position: absolute;
}
.cases-wrapper .backsource-wrapper .mobile .decorate.decorate-1 {
  left: -4.635416666666667vw;
  top: 5.885416666666667vw;
  width: 5.364583333333333vw;
  height: 5.15625vw;
}
.cases-wrapper .backsource-wrapper .mobile .decorate.decorate-2 {
  top: 11.25vw;
  right: -3.3854166666666665vw;
  width: 15.364583333333334vw;
  height: 19.010416666666664vw;
}
.cases-wrapper .backsource-wrapper .mobile .decorate.decorate-3 {
  left: -7.5vw;
  bottom: 6.614583333333333vw;
  width: 10.520833333333334vw;
  height: 10.3125vw;
}
.cases-wrapper .swiper-section {
  position: relative;
  padding-top: 4.947916666666666vw;
  padding-bottom: 3.125vw;
}
.cases-wrapper .swiper-section .act {
  position: absolute;
  top: 3.177083333333333vw;
  left: 50%;
  transform: translateX(-50%);
  width: 22.03125vw;
}
.cases-wrapper .swiper-section > h2.title {
  margin: 0;
  margin-bottom: 0.46875vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff6600;
}
.cases-wrapper .swiper-section > .desc {
  text-align: center;
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.cases-wrapper .swiper-section > .swiper {
  position: relative;
  margin-top: 0.6770833333333334vw;
  padding: 0 15.625vw;
}
.cases-wrapper .swiper-section > .swiper .act-swiper-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2.2916666666666665vw;
  height: 2.2916666666666665vw;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}
.cases-wrapper .swiper-section > .swiper .act-swiper-arrow.swiper-button-disabled {
  display: none;
}
.cases-wrapper .swiper-section > .swiper .act-swiper-arrow.prev {
  left: 13.333333333333334vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16252291642899934.png);
}
.cases-wrapper .swiper-section > .swiper .act-swiper-arrow.next {
  right: 13.333333333333334vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16252259933873886.png);
}
.cases-wrapper .swiper-section > .swiper .act-swiper-pagination {
  position: absolute;
  z-index: 100;
  bottom: 0.46875vw;
  left: 50%;
  transform: translateX(-50%);
}
.cases-wrapper .swiper-section > .swiper .act-swiper-pagination /deep/ .swiper-pagination-bullet {
  width: 1.25vw;
  height: 0.3125vw;
  margin-right: 0.4166666666666667vw;
  border-radius: 0.20833333333333334vw;
  background-color: #ddd;
}
.cases-wrapper .swiper-section > .swiper .act-swiper-pagination /deep/ .swiper-pagination-bullet:last-child {
  margin-right: 0;
}
.cases-wrapper .swiper-section > .swiper .act-swiper-pagination /deep/ .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #ff6600;
}
.cases-wrapper .swiper-section > .swiper .card-item {
  display: flex;
  align-items: center;
}
.cases-wrapper .swiper-section > .swiper .card-item .info {
  margin-right: 11.510416666666666vw;
}
.cases-wrapper .swiper-section > .swiper .card-item .info .title {
  margin-bottom: 1.5625vw;
  line-height: 1.4;
  font-size: 1.5625vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.cases-wrapper .swiper-section > .swiper .card-item .info .data-box {
  display: flex;
  align-items: center;
}
.cases-wrapper .swiper-section > .swiper .card-item .info .data-box .data-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 3.125vw;
}
.cases-wrapper .swiper-section > .swiper .card-item .info .data-box .data-item:last-child {
  margin-right: 0;
}
.cases-wrapper .swiper-section > .swiper .card-item .info .data-box .data-item:nth-child(2) {
  width: 5.885416666666667vw;
}
.cases-wrapper .swiper-section > .swiper .card-item .info .data-box .data-item .num-line {
  display: flex;
  align-items: flex-end;
}
.cases-wrapper .swiper-section > .swiper .card-item .info .data-box .data-item .num-line .num {
  line-height: 3.5416666666666665vw;
  font-size: 3.125vw;
  font-family: DINCond-Light, DINCond;
  font-weight: 300;
  color: #333333;
}
.cases-wrapper .swiper-section > .swiper .card-item .info .data-box .data-item .num-line .text,
.cases-wrapper .swiper-section > .swiper .card-item .info .data-box .data-item .num-line .unit,
.cases-wrapper .swiper-section > .swiper .card-item .info .data-box .data-item .num-line .plus {
  line-height: 1.4;
  font-size: 1.0416666666666665vw;
  font-family: PingFangSC-Thin, PingFang SC;
  font-weight: 100;
  color: #333333;
}
.cases-wrapper .swiper-section > .swiper .card-item .info .data-box .data-item .num-line .text,
.cases-wrapper .swiper-section > .swiper .card-item .info .data-box .data-item .num-line .unit {
  margin-bottom: 0.5208333333333333vw;
}
.cases-wrapper .swiper-section > .swiper .card-item .info .data-box .data-item .num-line .plus {
  align-self: center;
}
.cases-wrapper .swiper-section > .swiper .card-item .info .data-box .data-item .text-line {
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #666666;
}
.cases-wrapper .swiper-section > .swiper .card-item .info .desc {
  width: 30.15625vw;
  margin-top: 2.1875vw;
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #666666;
}
.cases-wrapper .swiper-section > .swiper .card-item .mobile .decorate {
  position: absolute;
}
.cases-wrapper .swiper-section > .swiper .card-item .mobile .decorate.decorate-a {
  left: -3.4375000000000004vw;
  top: 6.354166666666666vw;
  width: 4.635416666666667vw;
  height: 4.6875vw;
}
.cases-wrapper .swiper-section > .swiper .card-item .mobile .decorate.decorate-b {
  right: -3.0729166666666665vw;
  top: 10.9375vw;
  width: 15.364583333333334vw;
  height: 19.010416666666664vw;
}
.cases-wrapper .swiper-section > .swiper .card-item .mobile .decorate.decorate-c {
  left: -7.447916666666667vw;
  bottom: 6.510416666666667vw;
  width: 10.520833333333334vw;
}
.cases-wrapper .swiper-section > .swiper .card-item .mobile .decorate.decorate-d {
  left: -3.4375000000000004vw;
  top: 4.427083333333334vw;
  width: 3.854166666666667vw;
}
.cases-wrapper .swiper-section > .swiper .card-item .mobile .decorate.decorate-e {
  right: -3.4375000000000004vw;
  top: 11.041666666666666vw;
  width: 15.364583333333334vw;
  height: 19.010416666666664vw;
}
.cases-wrapper .swiper-section > .swiper .card-item .mobile .decorate.decorate-f {
  left: -7.708333333333334vw;
  bottom: 6.406249999999999vw;
  width: 10.520833333333334vw;
}
.cases-wrapper .marketing-activity {
  background-color: #f8f8f8;
}
.cases-wrapper .live-broadcast {
  background-color: #fff;
}
.cases-wrapper .live-broadcast > .swiper .card-item .mobile {
  margin-left: 5.729166666666666vw;
  margin-right: 11.145833333333334vw;
}
.cases-wrapper .live-broadcast > .swiper .card-item .info {
  margin-right: 0;
}
