


















































































































































.box {
  display: flex;
  align-items: center;
  .icon {
    width: 28px;
    height: 28px;
    margin-right: 9px;
  }
  .text {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ff6600;
  }
}
.anchor-container {
  .review_head {
    height: 90px;
    display: flex;
    justify-content: space-between;
    margin: 0 240px;
    align-items: center;
    font-size: 1.5vw;
    font-weight: 600;
    color: #333;

    .left,
    .right {
      cursor: pointer;
      a {
        text-decoration: none;
        color: #333;
      }
    }

    .right {
      font-weight: 400;
    }
    .right:hover {
      border-bottom: 3px solid #ff6600;
    }
  }
  .section {
    transition: all 0.5s;

    &:nth-child(2) {
      padding-top: 60px;
      background-color: #fff;
    }
    &:nth-child(3) {
      padding-top: 60px;
      background-color: #fff;
    }
  }
}
